const searchSettings = function(model) {
  return {
    theme: 'bootstrap',
    minimumInputLength: 2,
    allowClear: true,
    placeholder: 'Type to search...',
    ajax: {
      url: `/${model}s/search`,
      delay: 250,
      dataType: 'json',
      data: (params) => ({ q: params.term }),
      processResults: (data) => ({ results: data })
    },
    templateSelection: (data) => {
      $('#slideshow_image_caption').val(data.caption)
      return formatResult(model, data)
    },
    templateResult: (data) => formatResult(model, data),
    escapeMarkup: (m) => m
  }
}

const formatResult = function(type, data) {
  if (!data.id) return
  return `<img class='${type}-select-option' src='${data.url}'> ${data.description}`
}


$(function() {
  $('.image-select, .video-select').each(function() {
    let model = this.className.match(/(\w+)-select/)[1]
    let settings = searchSettings(model)
    let select = $(this)
    let option = select.children('option:selected')

    if (option.length > 0) {
      $.ajax({
        url: `/${model}s/${option.val()}`,
        dataType: 'json'
      }).done(function(data) {
        option.remove()
        settings.data = [data]
        select.select2(settings)
      }).fail(function() {
        option.remove()
        select.select2(settings)
      })
    } else {
      select.select2(settings)
    }
  })
})
