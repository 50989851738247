const contentCarousel = function() {
  let el = $('.content-carousel.owl-carousel')
  let singleSlide = el.children('img').length <= 1

  el.owlCarousel({
    items: (singleSlide ? 2 : 1), // carousel breaks with a single slide and items: 1
    center: true,
    loop: true,
    margin: 0,
    autoWidth: singleSlide, // same as above, breaks with a single slide and autoWidth: false
    dots: true,
    nav: true,
    navText: ['&#12296;','&#12297;'],
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true
  })
}

const autofillPlaceholders = function(data) {
  $('#content_reference_title')      .attr('placeholder', data.title)
  $('#content_reference_description').attr('placeholder', data.description)
  $('#content_reference_uri')        .attr('placeholder', data.url)
}

const searchSettings = function(url) {
  return {
    theme: 'bootstrap',
    minimumInputLength: 2,
    allowClear: true,
    ajax: {
      url: `/${url}/search`,
      dataType: 'json',
      data: (term) => ({ q: term }),
      processResults: (data) => ({ results: data })
    },
    templateSelection: (data) => {
      autofillPlaceholders(data)
      return data.title || data.text
    },
    templateResult: (data) => data.title
  }
}

$(function() {
  $('.posting-select').select2(searchSettings('postings'))
  $('.page-select')   .select2(searchSettings('pages'))

  contentCarousel()

  let collection = $('.collection-sortable')
  collection.sortable({
    update: function() {
      let positions = $('.content-reference').map((i, e) => $(e).attr('data-id'))
      $.post(`/content_collections/${collection.data('collection-id')}/reorder`, { 'positions': positions.get(), '_method': 'patch' }, function(data) {
        $('.show-collection').html(data)
        contentCarousel()
      }).fail(() => { collection.sortable('cancel') })
    }
  })
})
