import '../css'

const youtubeId = function() {
  if ($('#video_youtube_url').length == 0) return
  let match = $('#video_youtube_url').val().match(/(?:youtube\.com\/watch\?(?:.*)v=|youtu\.be\/)([^&?\n]+)/)
  return match && match[1]
}

const generateEmbed = function(id) {
  if (!id) return
  $('#youtube-video').html(`<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/${id}"></iframe>`)
}

$(document).on('input', '#video_youtube_url', function() {
  let id = youtubeId()
  if (id) {
    $('#video_image_url').val(`https://i.ytimg.com/vi/${id}/hqdefault.jpg`)
    generateEmbed(id)
  }
})

$(function() {
  generateEmbed(youtubeId())
})
