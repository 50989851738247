$(document).on('click', '#url-copy', function() {
  let btn = $(this)
  let input = btn.parents('.input-group').find('input')
  input.select()
  document.execCommand('copy')
  input.blur()

  btn.removeClass('btn-default').addClass('btn-info').text('Copied')
  setTimeout(() => { btn.removeClass('btn-info').addClass('btn-default').text('Copy') }, 1000)
})
