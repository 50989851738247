$(function() {
  $('#user_search').select2({
    theme: 'bootstrap',
    placeholder: 'Search for a user...',
    minimumInputLength: 3,
    templateSelection: function(result) {
      $('#user_auth_id').val(result.auth_uuid)
      $('#user_name').val(result.name)
      $('#user_username').val(result.username)
      $('#user_email').val(result.email)
      return result.text
    },
    ajax: {
      url: '/users/auth-search',
      delay: 250
    }
  })
})
