import '../css'

import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'

Dropzone.autoDiscover = false
Dropzone.options.multiImageUpload = {
  autoDiscover: false,
  paramName: 'image[file]',
  maxFilesize: 32,
  previewTemplate: `
    <div class="dz-preview dz-file-preview">
      <div class="dz-details">
        <div class="dz-filename"><span data-dz-name></span></div>
        <div class="dz-size" data-dz-size></div>
        <img data-dz-thumbnail />
      </div>
      <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
      <div class="dz-success-mark"><span>✔</span></div>
      <div class="dz-error-mark"><span>✘</span></div>
      <div class="dz-error-message"><span data-dz-errormessage></span></div>
      <div class="dz-credit hidden" data-toggle="tooltip">Credit</div>
      <div class="dz-description hidden" data-toggle="tooltip">Description</div>
    </div>`,
  init: function() {
    this.on('success', function(file, data) {
      ['credit', 'description'].forEach(function(name) {
        if (!data[name]) return
        let el = $(`.dz-${name}`)
        el.attr('title', data[name])
        el.tooltip({
          placement: 'right',
          container: 'body'
        })
        el.removeClass('hidden')
      })
    })
  }
}

$(function() {
  $('form.dropzone').dropzone()
})

$(document).on('change', '#image_file', function(e) {
  let data = new FormData()
  data.append('image_file', e.target.files[0])

  $.ajax({
    url: '/images/preview',
    type: 'POST',
    data: data,
    cache: false,
    dataType: 'json',
    processData: false,
    contentType: false
  }).done(function(data) {
    $('#image_credit'       ).val(data.credit)
    $('#image_description'  ).val(data.description)
    $('#image_retained_file').val(data.retained_file)
    $('.image-preview').html(`<img src="${data.url}">`)
  })
})

$(document).on('click', 'img[data-toggle="modal"]', function() {
  $('#image-preview .modal-body').html(`<img src="${$(this).data('url')}">`)
})
