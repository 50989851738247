$(function() {
  $('table.album, table.album-tracks').sortable({
    items: 'tr[data-id]',
    handle: '.fa-arrows-alt-v',
    axis: 'y',
    helper: function(e, tr) {
      var originals = tr.children()
      var helper = tr.clone()
      helper.children().each(function(ix) {
        $(this).width(originals.eq(ix).outerWidth())
      })

      return helper
    },
    start: function(e, ui) {
      $('.ui-sortable-placeholder').height(ui.helper.height())
    },
    update: function(e, ui) {
      var item = ui.item
      $.ajax({
        url: $(this).attr('data-url'),
        type: 'PATCH',
        data: { id: item.attr('data-id'), index: item.index('tr[data-id]') },
        dataType: 'script'
      })
    }
  })
})
