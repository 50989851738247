const checkLengths = function() {
  let title = $('#posting_title')

  if (selectedHome() && (title.val().length > 36 || title.attr('data-long') == 'true')) {
    if ($('.posting_title .long').length == 0) {
      $('.posting_title').append('<span class="text-danger error-block long">Long title for home page, consider shortening</span>')
    }
  } else {
    $('.posting_title .long').remove()
  }

  let quip = $('#posting_quip')

  if (selectedHome() && (quip.val().length > 90 || quip.attr('data-long') == 'true')) {
    if ($('.posting_quip .long').length == 0) {
      $('.posting_quip').append('<span class="text-danger error-block long">Long quip for home page, consider shortening</span>')
    }
  } else {
    $('.posting_quip .long').remove()
  }
}

const selectedHome = () => $('#posting_target_id option:selected').text() == 'Home'

const showHideFields = function(speed=0) {
  if ($('#posting_target_id option:selected').parent().attr('label') == 'View Target')
    $('.posting_parent').show(speed)
  else
    $('.posting_parent').hide(speed)
}

$(showHideFields)

$(document).on('change', '#posting_target_id', function() {
  showHideFields('slow')
  checkLengths()
})

$(document).on('keyup', '#posting_title, #posting_alt_quip', function() {
  checkLengths()
})
