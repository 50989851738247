import '../css'
import '../postings/js'

// FIXME: datetimepicker is bugging out, replace with tempusdominus
// $(function() {
//   $('#publish_date_range_start, #publish_date_range_end').datetimepicker({
//     format: 'YYYY-MM-DD',
//     useCurrent: true,
//     showClear: true
//   })
// })

$(document).on('click', '.toggle.document', function() {
  let el = $(this)
  $.post(`${location.href}/toggle`, { 'field' : el.attr('data-field') }, function(data) {
    if (el.attr('data-field') == 'proofreader')
      location.reload()
    else
      el.find('span').html(data)
  })
})

$(document).on('click', '.document-main .toggle.posting', function() {
  let el = $(this)
  $.post(`${location.href}/targets/${el.attr('data-posting-id')}/toggle`, { 'field' : el.attr('data-field') }, function(data) {
    let newEl = $.parseHTML(data)
    el.tooltip('destroy')
    el.replaceWith(newEl)
    $(newEl).tooltip().tooltip('show')
  })
})
