import ujs from '@rails/ujs'
ujs.start()

import '@fortawesome/fontawesome-free/css/all'

import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'

import 'bootstrap'
import 'bootswatch/yeti/bootstrap.css'

import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'
import 'jquery-ui/ui/widgets/sortable'
import 'jquery-ui/themes/base/core.css'

import 'owl.carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import 'application/css'
import 'nexus/application/js'
import 'nexus/albums/js'
import 'nexus/authors/js'
import 'nexus/content_collections/css'
import 'nexus/content_references/js'
import 'nexus/documents/js'
import 'nexus/downloads/js'
import 'nexus/general/css'
import 'nexus/images/js'
import 'nexus/magazines/js'
import 'nexus/pages/js'
import 'nexus/publish_targets/js'
import 'nexus/slideshows/js'
import 'nexus/shortlinks/js'
import 'nexus/users/js'
import 'nexus/videos/js'

//= require jquery-ui/ui/version
//= require jquery-ui/ui/data
//= require jquery-ui/ui/ie
//= require jquery-ui/ui/plugin
//= require jquery-ui/ui/safe-active-element
//= require jquery-ui/ui/safe-blur
//= require jquery-ui/ui/scroll-parent
//= require jquery-ui/ui/widget
//= require jquery-ui/ui/widgets/mouse
//= require jquery-ui/ui/widgets/draggable
//= require jquery-ui/ui/widgets/droppable
//= require jquery-ui/ui/widgets/sortable
