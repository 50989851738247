import 'eonasdan-bootstrap-datetimepicker'
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'

$(function() {
  $('.datetimepicker.input-group').datetimepicker({
    icons: {
      time: 'far fa-clock',
      date: 'fas fa-calendar',
      up:   'fas fa-chevron-up',
      down: 'fas fa-chevron-down'
    }
  })
})
