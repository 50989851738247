import '../css'

const activateSlideshow = function() {
  let el = $('.slideshow .owl-carousel')
  let autoplay = el.attr('data-autoplay') != 'false'
  let timeout = el.attr('data-timeout') == '' ? 5000 : parseInt(el.attr('data-timeout'))

  el.owlCarousel({
    items: 1,
    loop: true,
    margin: 0,
    autoWidth: false,
    dots: true,
    nav: true,
    navText: ['&#12296;','&#12297;'],
    autoplay: autoplay,
    autoplayTimeout: timeout,
    autoplayHoverPause: true,
    onTranslated: captionCredit,
    onInitialized: function() {
      captionCredit()
      $('.caption').show()
    }
  })
}

const captionCredit = function() {
  let obj = $('.owl-item.active img')
  $('.caption').html(obj.attr('data-caption'))
  $('.credit').html(obj.attr('data-credit'))
}

$(function() {
  activateSlideshow()

  let slideshowImages = $('.slideshow-sortable')
  slideshowImages.sortable({
    update: function() {
      let positions = $('.slideshow-image').map((i, e) => $(e).attr('data-id'))
      $.post(`/slideshows/${slideshowImages.data('slideshow-id')}/reorder`, { 'positions': positions.get(), '_method': 'patch' }, function(data) {
        $('.show-slideshow').html(data)
        activateSlideshow()
      }).fail(function() {
        slideshowImages.sortable('cancel')
      })
    }
  })
})
