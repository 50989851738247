const fillMonthYear = function(id, prefix) {
  let matches = $(`#${id}_pdf`).val().match(new RegExp(`${prefix}(\\d{4})(0|)(\\d{1,2})_EN.pdf`))

  if (matches) {
    $(`#${id}_year`).val(matches[1])
    $(`#${id}_month`).val(matches[3])
  }
}

$(document).on('change', '#philadelphian_pdf', function() {
  fillMonthYear('philadelphian', 'PN')
})
