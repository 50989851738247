$(document).on('click', '.delete-icon, .delete-button', function() {
  let el       = $(this),
    title      = el.attr('data-title'),
    text       = el.attr('data-text'),
    action     = el.attr('data-action'),
    buttonText = el.attr('data-button-text'),
    image      = el.attr('data-image')

  let modal = $('#delete-modal')
  title      && modal.find('.modal-title').html(title)
  action     && modal.find('form').attr('action', action)
  buttonText && modal.find('.delete-button').val(buttonText)
  image      && modal.find('.media-object').attr('src', image)

  if (!text) return
  if (modal.find('.media-body').length > 0)
    modal.find('.media-body').html(text)
  else
    modal.find('.modal-body').html(text)
})
