import '../css'

const run = function() {
  $('.target-draggable').draggable({
    revert: 'invalid',
    handle: '.fa-sitemap',
    stack: '.target-draggable'
  })

  $('.target-droppable').droppable({
    accept: '.target-draggable',
    drop: function(e, ui) {
      if (ui.draggable.is('.ui-draggable-dragging')) {
        let child_id = ui.draggable.attr('data-target-id')
        let parent_id = $(this).attr('data-target-id')
        $.post(`/targets/${child_id}/parent`, { parent_id: parent_id }, function(data) {
          $('.publish-targets-container').html(data)
          run()
        })
      }
    }
  })
}

$(run)

$(document).on('click', '.target-toggle-active', function() {
  let el = this
  let target_id = $(this).attr('data-target-id')
  $.post(`/targets/${target_id}/toggle-active`, function() {
    $(el).toggleClass('fa-check fa-times')
  })
})

$(document).on('click', '.view-target-toggle-active', function() {
  let el = this
  let target_id = $(this).attr('data-target-id')
  $.post(`/view_targets/${target_id}/toggle-active`, function() {
    $(el).toggleClass('fa-check fa-times')
  })
})
