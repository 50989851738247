import URI from 'urijs'

const applyScopes = function() {
  let uri = new URI()
  $('.scope').each(function() {
    let scope = $(this).attr('name')
    let value = $(this).val()

    value != '' ? uri.setQuery(scope, value) : uri.removeQuery(scope)
  })

  location.href = uri.normalizeQuery().toString()
}

$(document).on('change', '.scope', applyScopes)

$(document).on('click', '.scope-trigger', function(e) {
  e.preventDefault()
  applyScopes()
})

$(document).on('click', '.reset-scopes', function(e) {
  e.preventDefault()
  location.href = location.pathname
})
