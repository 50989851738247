import '../css'

const run = function() {
  $('.page-draggable').draggable({
    revert: 'invalid',
    handle: '.fa-sitemap',
    stack: '.page-draggable'
  })

  $('.page-droppable').droppable({
    accept: '.page-draggable',
    drop: function(e, ui) {
      if (ui.draggable.is('.ui-draggable-dragging')) {
        let child_id = ui.draggable.attr('data-page-id')
        let parent_id = $(this).attr('data-page-id')
        $.post(`/pages/${child_id}/parent`, { parent_id: parent_id }, function(data) {
          $('.pages-container').html(data)
          run()
        })
      }
    }
  })

  $('table.pages').sortable({
    items: '.page-sortable',
    handle: '.fa-bars',
    axis: 'y',
    helper: function(e, tr) {
      let originals = tr.children()
      let helper = tr.clone()
      helper.children().each(function(ix) {
        $(this).width(originals.eq(ix).outerWidth())
      })

      return helper
    },
    start: function(e, ui) {
      $('td').each(function() {
        $(this).width($(this).width())
      })
      $('tr').not(`[data-level='${ui.item.attr('data-level')}']`).fadeTo(200, 0, function() {
        $('.ui-sortable-placeholder').show()
      })
    },
    stop: function(e, ui) {
      let item = ui.item
      $.post(`/pages/${item.attr('data-page-id')}/reorder`, { position: item.index(`[data-level='${item.attr('data-level')}']`) }, function(data) {
        $('.pages-container').html(data)
        run()
      })
    }
  })

  $('.document-select').select2({
    theme: 'bootstrap',
    minimumInputLength: 2,
    allowClear: true,
    ajax: {
      url: '/documents/search?media=page',
      delay: 250,
      dataType: 'json',
      data: (term) => ({ q: term }),
      processResults: (data) => ({ results: data }),
    },
    templateSelection: (data) => (data.title || data.text),
    templateResult: (data) => data.title
  })
}

$(run)
