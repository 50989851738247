import '../css'

$(document).on('keyup', '#shortlink_long_url', function() {
  $('#shortlink_posting_id').val('').trigger('change')
})

$(document).on('select2:select', '#shortlink_posting_id', function() {
  $('#shortlink_long_url').val('')
})

$(function() {
  $('#shortlink_posting_id').select2({
    theme: 'bootstrap',
    minimumInputLength: 2,
    allowClear: true,
    ajax: {
      url: '/postings/search',
      dataType: 'json',
      data: (term) => ({ q: term }),
      processResults: (data) => ({ results: data })
    },
    templateSelection: (data) => (data.title || data.text),
    templateResult: (data) => data.title
  })
})
